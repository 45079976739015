.gmap-contact {
    position: relative;

    ul.map-data {
        position: absolute;
        bottom: 14%;
        left: 0;
        right: 0;
        width: 50%;
        margin: auto;
        background-color: $blue-grey;
        padding: 3em 2em;
        list-style: none;
        @include mq(null,1023px) {
            width: 62%;
            padding: 2em 1.33em;
        }

        .page--contact & {
            display: none;
        }

        li {
            @include montserrat(700);
            @include font-size-lh(16,20);
            color: $white;

            .txt-5 {
                @include font-size-lh(20,25);
                margin-top: 1em;

                .picto-phone {
                    max-width: 20px;
                    width: 100%;
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }
        }
    }

    .map-wrapper {
        position: relative;
        padding-bottom: 123.2%;
        background-color: $black;
        margin-bottom: 0;

        .map-container,
        .googlemaps-canvas {
            @include position(absolute,0,null,null,0);
            width: 100%;
            height: 100%;
        }

        .infowindowcontent {
            font-size: 14px;
            line-height: 1.35;
            overflow: hidden;
            padding-right: 12px;
            padding-bottom: 12px;
            p {
                margin: 10px 0;
            }

            .txt-5 {
                .picto-phone {
                    max-width: 20px;
                    width: 100%;
                    max-height: 22px;
                    height: 100%;
                    vertical-align: middle;
                    margin-right: 5px;

                    .cls-1 {
                        fill: $blue-grey;
                    }
                }
            }
        }
    }

    .map-infow-window {

    }
}

.gm-style-pbt {
    line-height: 1.3;
    margin: 0 auto;
    max-width: 400px;
}