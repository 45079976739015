$thumbnail-size: 250px;
$product-description-height: 70px;

.products {
    #index &,
    #cart & {
        justify-content: center;
    }
    #category & {
        justify-content: flex-start;
    }
}
.product-miniature {
    #product &,
    #index &,
    #cart & {
        width: 25%;
    }
    #category & {
        width: calc(100% / 3);
    }
}

.willowimpressionplan--push-products,
.featured-products,
.product-accessories,
.product-miniature  {
    .products {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        #product &,
        #index &,
        #cart & {
            padding: 0 50px;
            @include mq(null,639px){
                max-width: 400px;
                margin: auto;
            }
        }
        &--arrow {
            position: absolute;
            top: 50%;
            width: 50px;
            height: 50px;
            padding: 0;
            margin: 0;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-100%);
            z-index: 2;
            background: none;
            transition: all .3s;
            &--prev {
                left: 0;
            }
            &--next {
                right: 0;
            }
            &.slick-disabled {
                opacity: .2;
            }
        }
        .slick {
            &-track {
                display: flex !important;
            }
            &-slide {
                height: inherit !important;
                > div {
                    height: 100%;
                }
            }
        }
    }
    .product-thumbnail {
        display: block;
    }
    .thumbnail-container {
        position: relative;
        background: white;
        //border-radius: 20px;
        //height: 100%;
        overflow: hidden;
        margin: 0 auto;

        .product-thumbnail {
            position: relative;
            padding-bottom: 100%;
            > img {
                width: 100%;
                max-width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
    .products-section-title {
        text-align: center;
    }
    //.product-title {
    //    //@include muli(600);
    //    //@include fslh(16px,20px);
    //    text-decoration: none;
    //    //color: $lpp-gray-dark;
    //    min-height: 60px;
    //    display: inline-block;
    //    text-align: left;
    //    text-transform: none;
    //    margin: 0;
    //}
    //.product-price-and-shipping {
    //    //@include brandon();
    //    //@include fontSize(20px);
    //    //color: $lpp-blue;
    //    text-align: right;
    //    margin-left: 5px;
    //    .discount-product {
    //        display: none;
    //    }
    //}
    //.variant-links {
    //    position: relative;
    //    text-align: center;
    //    width: 100%;
    //    top: -0.25em;
    //    padding-top: 0.1875rem;
    //    min-height: 2.5rem;
    //    background: white;
    //}
    .highlighted-informations {
        position: absolute;
        bottom: 1.25rem;
        padding-top: 0.625rem;
        z-index: 2;
        background: white;
        text-align: center;
        width: $thumbnail-size;
        height: 3.125rem;
        box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
        transition: bottom .3s;
        .quick-view {
            //color: $gray;
            //font-size: $base-font-size;
            &:hover {
                //color: $brand-primary;
            }
        }
    }
    .product-description {
        position: relative;
    }
    .slick-slide {
        position: relative;
        .product-miniature {
            height: 100%;
        }
    }
    .product-miniature {
        margin: 0;
        padding: 10px;
        width: 100%;
        @include mq(480px,null){
            width: calc(100% / 2);
        }
        @include mq(1024px,null){
            width: calc(100% / 3);
            #search & {
                width: calc(100% / 4);
            }
        }
        @include mq(1280px,null){
            #search & {
                width: calc(100% / 4);
            }
        }
        .product-flags {
            pointer-events: none;
            li.product-flag {
                min-width: 3.125rem;
                min-height: 1.875rem;
                font-weight: 600;
                &.online-only {
                    top: 13.1rem;
                }
            }
        }
    }
    .comments_note {
        text-align: center;
        //color: $gray;
    }
    .regular-price {
        //color: $gray;
        text-decoration: line-through;
        //font-size: $font-size-sm;
    }
    .count {
        //color: $gray;
        font-weight: 700;
        position: relative;
        bottom: 0.5rem;
    }
    .all-product-link {
        clear: both;
        //color: $gray;
        font-weight: 700;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}


//.featured-suggestions {
//    .thumbnail-container  {
//        border-bottom-left-radius: 0 !important;
//        border-bottom-right-radius: 0 !important;
//    }
//    .products {
//        .slick-list {
//            padding-bottom: 50px;
//        }
//        .product--add {
//            width: calc(100% - 20px);
//            margin: 0 10px;
//            padding: 20px;
//            background-color: $lpp-blue;
//            border-radius: 0 0 20px 20px;
//            @include brandonBlackSmall;
//            text-transform: uppercase;
//            color: white;
//            text-align: center;
//            display: flex;
//            justify-content: center;
//            align-items: center;
//            border: none;
//            cursor: pointer;
//            position: absolute;
//            bottom: -50px;
//            left: 0;
//            z-index: 2;
//        }
//    }
//}