.home-news {
    overflow: hidden;
    margin-top: 20px;
    &--title {
        @include montserrat(600);
        @include font-size-lh(25, 30);
        color: $blue-grey;
        text-align: center;
    }
    &--items {
        white-space: nowrap;
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 440px;
        max-width: 100%;
        margin: auto;
        @include mq(1024px,null){
            width: 100%;
            padding-bottom: 90px;
        }
    }
    &--item {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        width: 444px;
        margin: 0 7px;
        &--hover {
            &--title {
                @include montserrat(600);
                @include font-size-lh(32, 34);
                margin-bottom: 10px;
            }
            &--title2 {
                @include montserrat(600);
                @include font-size-lh(24, 24);
                margin-bottom: 25px;
            }
            &:hover {
                .home-news--item--img--hover {
                    opacity: 1;
                }
                .home-news--item--logo {
                    .cls-4,
                    .cls-6 {
                        fill: $white;
                    }
                    .cls-4 {
                        stroke: $white;
                    }
                    .cls-5 {
                        fill: $white;
                    }
                    .cls-7 {
                        fill: $white;
                    }
                }
            }
        }
        &--img {
            &--inner {
                position: relative;
                max-width: 100%;
                @include mq(1280px,null){
                    width: 444px;
                    height: 444px;
                }
            }
            &--hover {
                @include montserrat();
                @include font-size-lh(20, 24);
                color: $white;
                text-align: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 20;
                background-color: rgba($pink, 0.75);
                opacity: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 30px;
                @include transition(opacity 0.3s);
                &--cta {
                    &--title {
                        @include montserrat(700);
                        @include font-size-lh(24, 24);
                        color: $pink;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $white;
                        padding: 15px 25px;
                        border-radius: 83px;
                        cursor: pointer;
                    }
                }
            }
        }
        &--logo {
            display: flex;
            justify-content: center;
            position: absolute;
            right: 0;
            bottom: 10px;
            left: 0;
            z-index: 10;
            //height: 100px;
            .logo {
                width: 59px;
                height: 52px;
                .cls-5 {
                    fill: $pink;
                }
            }
        }
        &--cta {
            margin-top: 15px;
            opacity: 0;
            @include transition(all 1s);
            position: relative;
            @include mq(1024px,null){
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                }
                &:before {
                    bottom: -52px;
                    right: -14px;
                    width: 14px;
                    height: 38px;
                    background-color: $pink;
                }
                &:after {
                    bottom: -14px;
                    right: -52px;
                    width: 38px;
                    height: 14px;
                    background-color: $blue-grey;
                }
            }
            .slick-current & {
                opacity: 1;
            }
            &--link {
                text-decoration: none;
                outline: none;
            }
            &--title {
                @include montserrat(600);
                @include font-size-lh(25, 25);
                color: $white;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                background-color: $pink;
                @include transition(background 0.3s);
                &:hover {
                    background-color: $blue-grey;
                }
            }
        }
    }
    .slick {
        $translateY: calc(-50% - 35px);
        &-arrow {
            position: absolute;
            top: 50%;
            z-index: 2;
            background-color: $white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @include mq(1280px,null){
                width: 83px;
                height: 83px;
            }
            svg {
                color: $blue-grey;
                width: 26px;
                height: 46px;
            }
        }
        &-prev {
            left: 10px;
            transform: translateY(calc(-50% - 46px));
        }
        &-next {
            right: 10px;
            transform: translateY(calc(-50% - 46px));
        }
        @include mq(1024px,null){
            &-prev {
                left: 10px;
                transform: translateY(calc(-50% - 70px));
            }
            &-next {
                right: 10px;
                transform: translateY(calc(-50% - 70px));
            }
        }
        @include mq(1475px,null){
            &-prev {
                left: 0;
                @include translate(-50%, #{$translateY});
            }
            &-next {
                right: 0;
                @include translate(50%, #{$translateY});
            }
        }
    }
}