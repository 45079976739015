@mixin font-size-lh($sizeValue: 16, $lineHeight:$sizeValue * 1.4) {
    font-size: $sizeValue + px;
    line-height: $lineHeight + px;
    //font-size: ($sizeValue / 10) + rem;
    //line-height: ($lineHeight / 10) + rem;
}
@mixin font-size($sizeValue: 16) {
    font-size: $sizeValue + px;
    font-size: ($sizeValue / 10) + rem;
}
@mixin dotdotdot($sizeValue: 16, $lineHeight:$sizeValue * 1.4, $nbRow:2) {
    position:relative;
    overflow:hidden;
    font-size: $sizeValue + px;
    line-height: $lineHeight + px;
    height: $lineHeight * $nbRow + px;
    //font-size: ($sizeValue / 10) + rem;
    //line-height: ($lineHeight / 10) + rem;
    //height: ($lineHeight / 10) * $nbRow + rem;
    padding: 0;
}
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color;
    background: rgba($color, $opacity);
}
@mixin position($type, $top: null, $right: null, $bottom: null, $left: null) {
    position: $type;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}
@mixin box-shadow($params) {
    -webkit-box-shadow : $params;
    -moz-box-shadow : $params;
    box-shadow : $params;
}
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -moz-transition-duration:    $duration;
    -o-transition-duration:      $duration;
    -webkit-transition-duration: $duration;
    transition-duration:         $duration;
}
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
/* transform */
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}
@mixin scale($scale) {
    @include transform(scale($scale));
}
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}
@mixin background-size($size:"cover") {
    -webkit-background-size: unquote($size);
    -moz-background-size: unquote($size);
    -o-background-size: unquote($size);
    background-size: unquote($size);
}
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}
@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
/* Filters */
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);
@mixin filter($filter-type,$filter-amount) {
    -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
       -moz-filter: $filter-type+unquote('(#{$filter-amount})');
        -ms-filter: $filter-type+unquote('(#{$filter-amount})');
         -o-filter: $filter-type+unquote('(#{$filter-amount})');
            filter: $filter-type+unquote('(#{$filter-amount})');
}
/* background-gradient V1 */
@mixin background-gradient($start-color:#333, $end-color:#999, $orientation:radial) {
    background: $start-color;
    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}
/* background-gradient V2 */
// https://www.sitepoint.com/building-linear-gradient-mixin-sass/
@function convert-angle($value, $unit) {
    $convertable-units: deg grad turn rad;
    $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
    @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
        @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
    }
    @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}
@function is-direction($value) {
    $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
    $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
    @return $is-direction or $is-angle;
}
@function legacy-direction($value) {
    @if is-direction($value) == false {
        @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
    }
    $conversion-map: (
            to top          : bottom,
            to top right    : bottom left,
            to right top    : left bottom,
            to right        : left,
            to bottom right : top left,
            to right bottom : left top,
            to bottom       : top,
            to bottom left  : top right,
            to left bottom  : right top,
            to left         : right,
            to left top     : right bottom,
            to top left     : bottom right
    );
    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }
    @return 90deg - convert-angle($value, 'deg');
}
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction) == false {
        $color-stops: ($direction, $color-stops);
        $direction: 180deg;
    }
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}
/* background-gradient V3 */
// https://github.com/drublic/Sass-Mixins/blob/master/partials/_multiple-colored-gradient.scss
@mixin x-multiple-colored-gradient ($args...) {
    $gradient: ();
    $pos: nth($args, 1);
    $pos_newsyntax: ();
    @if not is-valid-keyword-direction($pos) {
        $pos: 'top';
    }
    // New Syntax
    @if $pos == 'top' {
        $pos_newsyntax: 'to bottom';
    } @else if $pos == 'right' {
        $pos_newsyntax: 'to left';
    } @else if $pos == 'bottom' {
        $pos_newsyntax: 'to top';
    } @else if $pos == 'left' {
        $pos_newsyntax: 'to right';
    }
    @each $g in $args {
        @if not is-valid-keyword-direction($g) {
            $gradient: append($gradient, $g, comma);
        }
    }
    background-image: -webkit-linear-gradient(unquote($pos), $gradient);
    background-image:         linear-gradient(unquote($pos_newsyntax), $gradient);
}
@function is-valid-keyword-direction($value) {
    @return not not index(
    'top' 'right' 'bottom' 'left'
    'to top' 'to right' 'to bottom' 'to left'
    'to top right' 'to right top'
    'to bottom right' 'to right bottom'
    'to top left' 'to left top'
    'to bottom left' 'to left bottom', $value);
}
/* Use :
.selector {
    @include x-multiple-colored-gradient(
                    'top',
                    #f22 0%,
                    #f2f 15%,
                    #22f 30%,
                    #2ff 45%,
                    #2f2 60%,
                    #2f2 75%,
                    #ff2 90%,
                    #f22 100%
    );
}
*/



/* Animation */
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
@mixin animation ($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -ms-animation-delay: $delay;
    -ms-animation-duration: $duration;
    -ms-animation-name: $animation;
    -ms-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}
/*
    Use :
    @include keyframe(fadeout) {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
    @include animation(10s, 5s, fadeout)
 */



@mixin center($width: null, $height: null) {
    position: absolute;
    top: 50%;
    left: 50%;

    @if not $width and not $height {
        transform: translate(-50%, -50%);
    } @else if $width and $height {
        width: $width;
        height: $height;
        margin: -($width / 2) #{0 0} -($height / 2);
    } @else if not $height {
        width: $width;
        margin-left: -($width / 2);
        transform: translateY(-50%);
    } @else {
        height: $height;
        margin-top: -($height / 2);
        transform: translateX(-50%);
    }
}
@mixin center-children {
    display: flex;
    justify-content: center;
    align-items: center;
}


// Custom MQ
@mixin mq($min, $max) {
    @if $min == null {
        @media (max-width: #{$max}) {
            @content;
        }
    } @else if $max == null {
        @media (min-width: #{$min}) {
            @content;
        }
    } @else {
        @media (min-width: #{$min}) and (max-width: #{$max}) {
            @content;
        }
    }
}