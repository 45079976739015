#footer {
    padding-top: 0;
    padding-bottom: 54px;
    @include mq(1024px,null){
        padding-bottom: 0;
    }
}
.footer {
    &--holidays {
        padding: 10px;
        &--txt {
            @include font-size-lh(20, 27.5);
            font-weight: 700;
            text-align: center;
            color: $white;
            margin: 0;
        }
    }
    &--reassurance {
        background-color: $blue-grey;
        padding: 55px 0 70px 0;
        .block-reassurance {
            padding-top: 50px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            overflow: hidden;
            @include mq(1024px,null){
                flex-wrap: nowrap;
            }
            &--item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 50%;
                min-height: 150px;
                position: relative;
                padding: 5px;
                @include mq(1024px,null){
                    flex-wrap: nowrap;
                    &:not(:first-child) {
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            width: 3px;
                            //background-image: url('/themes/impression-plan/assets/dest/img/border-reinsurance.svg');
                            //background-repeat: repeat-y;
                            border-left: 3px dotted $pink;
                        }
                    }
                    &--0 {
                        width: 115px;
                    }
                    &--1 {
                        width: 205px;
                    }
                    &--2 {
                        width: 120px;
                    }
                    &--3 {
                        width: 155px;
                    }
                }
            }
            &--img {
                display: flex;
                justify-content: center;
            }
            &--title {
                @include montserrat(600);
                @include font-size-lh(18, 23);
                color: $white;
                text-align: center;
                margin-top: 13px;
            }
        }
    }
    &--logo {
        display: flex;
        justify-content: center;
        .logo {
            width: 157px;
            height: 138px;
            filter: drop-shadow(0px 0px 1px rgba(255,255,255,.75));
            .cls-4 {
                stroke: $white;
            }
            .cls-5 {
                fill: $pink;
            }
            .cls-4,
            .cls-6 {
                fill: $white;
            }
            .cls-7 {
                fill: $blue-grey;
            }
        }
    }
    &--inner {
        background-color: $pink;
        padding: 25px 0;
    }
    &--sub-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        @include mq(1024px,null){
            flex-wrap: nowrap;
        }
        &--li {
            margin: 5px 20px;
            @include mq(1024px,null){
                display: inline-flex;
                margin: 0;
                &:not(:first-child) {
                    &:before {
                        content: '•';
                        @include montserrat(600);
                        @include font-size-lh(14, 16);
                        color: $white;
                        padding: 0 20px;
                    }
                }
            }
        }
        &--link {
            text-decoration: none;
            outline: none;
        }
        &--title {
            @include montserrat(700);
            @include font-size-lh(14, 16);
            color: $white;
            text-transform: uppercase;
        }
    }
}