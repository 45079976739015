$sans-serif: Tahoma, Arial, sans-serif;
$serif: "Times New Roman", Times, serif;
$arial: Arial, Tahoma, sans-serif;;
$times: $serif;
@mixin arial($font-weight: 400) {
    font-family: $arial;
    font-weight: $font-weight;
}
@mixin times($font-weight: 400) {
    font-family: $times;
    font-weight: $font-weight;
}
$lato: 'Lato', $sans-serif;
@mixin lato($font-weight: 400) {
    font-family: $lato;
    font-weight: $font-weight;
}
$montserrat: 'Montserrat', $sans-serif;
@mixin montserrat($font-weight: 400) {
    font-family: $montserrat;
    font-weight: $font-weight;
}
$opensans: 'Open Sans', $sans-serif;
@mixin opensans($font-weight: 400) {
    font-family: $montserrat;
    font-weight: $font-weight;
}