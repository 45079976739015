/**************************\
  Basic Modal Styles
\**************************/

.modal {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    background-color: #fff;
    padding: 30px;
    max-width: 500px;
    max-height: 100vh;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
    //.success & {
    //  background-color: lighten($valid, 55%);
    //}
    .error & {
      background-color: lighten($error, 55%);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    box-sizing: border-box;
    .success & {
      color:$valid;
    }
    .error & {
      color: $error;
    }
  }

  &__close {
    background: transparent;
    border: 0;
  }

  &__header &__close:before {
    content: "\2715";
  }

  &__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, .8);
  }

  &__btn {
    font-size: .875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #e6e6e6;
    color: rgba(0, 0, 0, .8);
    border-radius: .25rem;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    text-transform: none;
    overflow: visible;
    line-height: 1.15;
    margin: 0;
    will-change: transform;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform .25s ease-out;
  }

  &__btn:focus, &__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  &__btn-primary {
    background-color: #00449e;
    color: #fff;
  }
}


/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] {
    .modal__overlay {
      animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .modal__container {
      animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
    }
  }

  &[aria-hidden="true"] {
    .modal__overlay {
      animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .modal__container {
      animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
    }
  }

  .modal__container,
  .modal__overlay {
    will-change: transform;
  }
}
