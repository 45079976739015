.page--contact {
    margin-bottom: 150px;
    .container--inner {
        @include mq(768px,null) {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
    &--inner {
        position: relative;
        @include mq(768px,null){
            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 15;
            }
            &:before {
                top: -19px;
                left: -71px;
                width: 52px;
                height: 19px;
                background-color: $blue-grey;
            }
            &:after {
                top: -71px;
                left: -19px;
                width: 19px;
                height: 52px;
                background-color: $pink;
            }
        }
    }
    &--content {
        margin-top: 20px;
        padding: 15px;
        border: 5px dashed rgba($pink, 0.8);
        //border-image-repeat: round;
        //border-image-slice: 5;
        //border-image-source: url('/themes/impression-plan/assets/dest/img/border-form-upload.svg');
        //border-image-width: 5px;
        position: relative;
        @include mq(768px,null){
            margin-top: 40px;
            padding: 30px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 15;
            }
            &:before {
                bottom: -19px;
                right: -71px;
                width: 52px;
                height: 19px;
                background-color: $pink;
            }
            &:after {
                bottom: -71px;
                right: -19px;
                width: 19px;
                height: 52px;
                background-color: $blue-grey;
            }
        }
    }
    &--map-form {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        @include mq(1024px,null){
            flex-direction: row;
        }
    }
    &--map {
        @include mq(1024px,null){
            flex: 1 0 45%;
        }
    }
    &--form {
        background-color: $grey;
        padding: 15px;
        @include mq(1024px,null){
            padding: 20px 30px;
            margin-left: 20px;
            flex: 1 1 55%;
            width: 66%;
        }
    }
}
.contact {
    &--form {
        &--fields {
            &--input {
                margin-top: 18px;
                input {
                    @include montserrat(700);
                    @include font-size-lh(16, 16);
                    color: $placeholder;
                    background-color: $white;
                    width: 100%;
                    height: 40px;
                    border: none;
                    padding: 0 20px;
                }
            }
            &--textarea {
                margin-top: 18px;
                textarea {
                    @include montserrat(700);
                    @include font-size-lh(16, 16);
                    color: $placeholder;
                    background-color: $white;
                    width: 100%;
                    height: 120px;
                    border: none;
                    padding: 20px;
                }
            }
            &--gdpr {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 18px;
            }
            &--button {
                margin-top: 35px;
                display: flex;
                justify-content: center;
            }
        }
        &--field {
            &--recaptcha {
                flex: 1 0 305px;
                margin-right: 10px;
            }
            &--gdpr {
                flex: 1 1 100%;
                label {
                    @include opensans(600);
                    @include font-size-lh(12, 15);
                    color: $label;
                    display: flex;
                }
                .custom-checkbox input[type="checkbox"] + span {
                    width: 17px;
                    height: 17px;
                    background-color: $white;
                    border: none;
                    flex: 1 0 17px;
                    .checkbox-checked {
                        margin: -0.125rem -0.125rem;
                        color: $label;
                    }
                }
            }
            &--button {
                @include montserrat(600);
                @include font-size-lh(25, 25);
                color: $white;
                text-transform: uppercase;
                padding: 15px 25px;
                background-color: $pink;
                border: none;
            }
        }
    }
    &--informations {
        display: flex;
        background-color: $blue-grey;
        padding: 15px 0 10px 0;
        margin-top: 30px;
        flex-direction: column;
        @include mq(768px,null){
            flex-direction: row;
        }
        &--item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            padding: 10px 5px;
            @include mq(768px,null){
                width: 33.333%;
            }
            &:not(:first-child) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    border-top: 3px dotted $pink;
                }
                @include mq(768px,null){
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 3px;
                        //background-image: url('/themes/impression-plan/assets/dest/img/border-reinsurance.svg');
                        //background-repeat: repeat-y;
                        border-left: 3px dotted $pink;
                    }
                }
            }
        }
        &--icon {}
        &--text {
            @include montserrat(600);
            @include font-size-lh(12,18);
            color: $white;
            text-align: center;
            margin-top: 10px;
            @include mq(1024px,null){
                @include font-size-lh(18,22);
            }
            a {
                color: $white;
            }
        }
    }
}