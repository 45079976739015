.page--cms {
    margin-bottom: 100px;

    &--content {
        h1, h2, h3, h4, h5, h6 {
            @include montserrat(700);
            color: $blue-grey;
        }

        p {
            @include montserrat();
            @include font-size-lh(20, 23);
            color: $blue-grey;
        }

        ul,
        ol {
            li {
                @include montserrat();
                @include font-size-lh(20, 23);
                color: $blue-grey;
            }
        }

         a {
             color: $pink;
         }

        &--image {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 1rem;

            .page--cms--content--inner & {
                max-width: 33.333%;
            }
        }

        &--inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &--text {
                max-width: 60%;
            }
        }
    }
}