.page--404 {
    margin-bottom: 100px;

    &--search {
        width: $w1080;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--txt {
            margin-bottom: 50px;

            &--1 {
                @include montserrat(700);
                @include font-size-lh(25, 30);
                color: $blue-grey;
            }

            &--2 {
                @include montserrat(300);
                @include font-size-lh(25, 30);
                color: $blue-grey;
                margin-left: 100px;
            }
        }

        &--form {
            position: relative;
            background-color: $grey;
            width: 100%;

            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 15;
            }

            &:before {
                top: -19px;
                left: -71px;
                width: 52px;
                height: 19px;
                background-color: $blue-grey;
            }

            &:after {
                top: -71px;
                left: -19px;
                width: 19px;
                height: 52px;
                background-color: $pink;
            }

            &--inner {
                position: relative;
                padding: 30px 40px;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 15;
                }

                &:before {
                    bottom: -19px;
                    right: -71px;
                    width: 52px;
                    height: 19px;
                    background-color: $pink;
                }

                &:after {
                    bottom: -71px;
                    right: -19px;
                    width: 19px;
                    height: 52px;
                    background-color: $blue-grey;
                }
            }
        }
    }
}