*,
*:after,
*:before {
    @include box-sizing(border-box);
}

.visuallyhidden {
    @include visuallyhidden;
}

.clearfix {
    @include clearfix;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
body,
p,
.h1, .h2, .h3 {
    color:$blue-grey;
}
a {
    color:$pink;
    &:hover,
    &:active,
    &:focus {
        //color:lighten($pink, 10%) !important;
        color: inherit;
    }
    &:active:focus,
    &:active:hover {
        //color:darken($pink, 5%);
        color: inherit;
    }
}
.page-my-account #content .links a {
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        span.link-item {
            box-shadow: 0 0 0 2px inset $pink;
        }
        i {
            color:$pink;
        }
    }
}
.form-control:focus,
.input-group.focus {
    outline-color: $pink;
}
.form-control:focus {
    border-color: $pink;
}

.page-my-account #content .links a span.link-item,
.page-addresses .address, .page-authentication #content {
    box-shadow: 0 0 0 2px inset #7a7a7a;
}
.page-customer-account #content {
    box-shadow: none;
}


.btn-primary,
.custom-radio input[type="radio"]:checked + span {
    background-color: $pink;
}
.btn-primary {
    margin: 5px 0;
    &:hover,
    &:active,
    &:focus {
        background-color:lighten($pink, 10%);
        color: $white !important;
    }
    &:active:focus,
    &:active:hover {
        background-color:darken($pink, 5%);
        color: $white !important;
    }
}

#wrapper {
    background-color: $white;
    padding-top: 0;
}

.container {
    width: 100%;
    padding: 0;

    &--inner {
        width: $widthContainer;
        max-width: 100%;
        margin: auto;
        padding: 0 15px;

        &--medium {
            width: 1396px;
            max-width: 100%;
            margin: auto;
            padding: 0 15px;
        }

        &--big {
            width: 1396px;
            max-width: 100%;
            margin: auto;
            padding: 0 15px;
        }
    }
}

#wrapper {
    box-shadow: none;
}

#main {
    .page-footer {
        display: none;
        .page-my-account & {
            display: block;
        }
    }

    .page-content {
        .page-contact & {
            margin-bottom: 0;
        }
        #notifications {
            margin-left:0;
            margin-right:0;
        }
    }
}

.card-block {
    .page-contact & {
        padding: 0;
    }
}

.card {
    .page-contact & {
        box-shadow: none;
        border: none;
    }
}

.breadcrumb {
    .page-contact &,
    .page-cms & {
        display: none;
    }
}
.btn {
    &--cta {
        @include montserrat(500);
        @include font-size-lh(15,17);
        color: $white;
        background-color: $pink;
        padding: 10px 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        text-decoration: none;
        @include transition(all 0.3s);

        &:hover {
            background-color: $blue-grey;
            color: $white !important;
        }

        svg {
            margin-left: 10px;
        }
    }

}