body#checkout {
    #footer {
        padding:0;
    }
    .product-name {
        font-weight:bold;
        &[data-toggle] {
            cursor:pointer;
        }
    }
    .product-quantity {
        display:inline-flex;
    }
    .product-unit-price {
        font-size: 1em;
    }
    .product-price {
        color: $pink;
        margin-left:10px;
    }
    .cart-summary-product-line {
        font-size: .8em;
        line-height: 1.25em;
    }
    section.checkout-step {
        box-shadow:none;
        padding-left:0;
        padding-right:0;
        .address-item.selected {
            border-color: $pink;
        }
        .delivery-option {
            margin-left:0;
            margin-right:0;
        }
    }
    .card {
        box-shadow: 0 0 0 2px inset #999;
        border:0;
    }

    .cart-summary-products {
        .media {
            margin-bottom:10px;
        }
    }

    .edit-cart {
        display: flex;
        justify-content: center;
        .btn-primary {
            background:#999;
            margin-bottom: 15px;
        }
    }
}
#order-confirmation {
    .card {
        box-shadow: 0 0 0 2px inset $blue-grey;
        border:0;
    }
}

.cart-modal {
    .product-quantity {
        align-items: center;
        .add {
            margin-bottom: 0;
            margin-right:5px;
        }
    }

}