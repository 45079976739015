.page--faq {
    &--search {
        width: $w1080;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        @include mq(768px,null){
            padding: 0 80px;
        }
        &--txt {
            margin-bottom: 50px;
            text-align: center;
            @include font-size-lh(22,26);
            @include mq(768px,null){
                text-align: left;
                @include font-size-lh(25,30);
            }
            &--1 {
                @include montserrat(700);
                color: $blue-grey;
            }
            &--2 {
                @include montserrat(300);
                color: $blue-grey;
                @include mq(768px,null){
                    margin-left: 100px;
                }
            }
        }
    }
    &--content {
        margin-top: 95px;
        margin-bottom: 120px;
        &--title {
            @include montserrat(700);
            @include font-size-lh(25, 30);
            color: $blue-grey;
            display: flex;
            justify-content: center;
        }
        &--items {
            margin-top: 20px;
            padding: 15px;
            border: 5px dashed rgba($pink, 0.8);
            //border-image-repeat: round;
            //border-image-slice: 5;
            //border-image-source: url('/themes/impression-plan/assets/dest/img/border-form-upload.svg');
            //border-image-width: 5px;
            @include mq(768px,null){
                margin-top: 40px;
                padding: 30px;
            }
        }
        &--no-items {
            @include montserrat();
            @include font-size-lh(25, 30);
            color: $blue-grey;
        }
        &--item {
            &:not(:first-child) {
                margin-top: 30px;
            }
            &--header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: $blue-grey;
                padding: 15px;
                @include mq(768px,null){
                    padding: 15px 25px;
                }
                .page--faq--content--item--link & {
                    cursor: pointer;
                }
                .page--faq--content--item--active & {
                    background-color: $pink;
                }
                &--title {
                    @include montserrat(700);
                    @include font-size-lh(16,20);
                    color: $white;
                    padding: 0;
                    width: calc(100% - 40px);
                    @include mq(768px,null){
                        @include font-size-lh(20,25);
                    }
                }
                &--picto {
                    position: relative;
                    width: 34px;
                    height: 34px;
                    .picto-more,
                    .picto-less {
                        width: 34px;
                        height: 34px;
                    }
                    &--more,
                    &--less {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        opacity: 0;
                        transition: all 0.3s;
                        z-index: 0;
                    }
                    &--less {
                        .page--faq--content--item--active & {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                    &--more {
                        opacity: 1;
                        z-index: 1;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                        .page--faq--content--item--active & {
                            opacity: 0;
                            z-index: 0;
                        }
                    }
                }
            }
            &--content {
                padding: 15px;
                border: 3px solid $blue-grey;
                border-top: none;
                transition: all 0.3s;
                display: none;
                @include mq(768px,null){
                    padding: 20px 32px;
                }
                .page--faq--content--item--active & {
                    border-color: $pink;
                }
            }
            &--txt {
                p {
                    @include montserrat();
                    @include font-size-lh(17,24);
                    color: $blue-grey;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}