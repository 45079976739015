.page-order-detail,
.page-customer-account {

    &,
    #content {
        color: $blue-grey;
    }

    .box {
        box-shadow: none;
        border: 2px solid $blue-grey;
    }
    h3,
    .thead-default th {
        color:inherit;
    }
}

.no-account {
    font-weight:bold;
}