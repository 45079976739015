.willowimpressionplan {
    position: relative;
    padding-top: 20px;
    @include mq(768px,null){
        padding-top: 0;
        top: -45px;
    }
    @include mq(1024px,null){
        top: -60px;
    }
    &--push-prices {
        padding: 25px 20px 50px;
        background-color: $white;
        display: flex;
        justify-content: space-between;
        flex: 1 0 auto;
        @include mq(null,767px){
            flex-direction: column;
        }
        @include mq(null,1023px){
            padding: 0;
        }
    }
    &--push-price {
        &--item {
            margin: 10px;
            position: relative;
            flex-grow: 1;
            @include mq(1024px,null){
                width: 50%;
                margin: 0 8px;
            }
        }
        &--content {
            background-color: $grey;
            position: relative;
            &:hover {
                .willowimpressionplan--push-price--hover {
                    opacity: 1;
                    z-index: 10;
                    pointer-events: auto;
                }
            }
        }
        &--colors {
            @include mq(null,767px){
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 100%;
            }
            @include mq(768px,1023px){
                position: absolute;
                top: 0;
                width: 10px;
                height: 100%;
                .willowimpressionplan--push-price--item--cmjn & {
                    right: 0;
                }
            }
            @include mq(1024px,null){
                position: relative;
                display: flex;
            }
        }
        &--color {
            @include mq(null,1023px){
                position: absolute;
                width: 100%;
            }
            @include mq(1024px,null){
                flex: 1 0 auto;
                height: 19px;
            }
            &--white {
                background-color: $white;
                border: 3px solid $black;
                @include mq(null,1023px){
                    top: 50%;
                    left: 0;
                    height: 50%;
                }
            }
            &--black {
                background-color: $black;
                @include mq(null,1023px){
                    top: 0;
                    left: 0;
                    height: 50%;
                }
            }
            &--cyan {
                background-color: $cyan;
                @include mq(null,1023px){
                    top: 0;
                    left: 0;
                    height: calc(100% / 3);
                }
            }
            &--magenta {
                background-color: $magenta;
                @include mq(null,1023px){
                    top: 33%;
                    left: 0;
                    height: calc(100% / 3);
                }
            }
            &--yellow {
                background-color: $yellow;
                @include mq(null,1023px){
                    top: 66%;
                    left: 0;
                    height: calc(100% / 3);
                }
            }
        }
        &--picto {
            position: absolute;
            z-index: 5;
            width: 33%;
            top: 50%;
            transform: translate(0%, -50%);
            display: none;
            @include mq(1024px,null){
                display: block;
                .willowimpressionplan--push-price--item--bw & {
                    left: -18px;
                }
                .willowimpressionplan--push-price--item--cmjn & {
                    right: -18px;
                }
            }
        }
        &--title-txt {
            text-align: center;
            padding: 10px 30px 15px;
            position: relative;
            z-index: 1;
            cursor: pointer;
            @include mq(1024px,null){
                cursor: auto;
                .willowimpressionplan--push-price--item--bw & {
                    margin-left: auto;
                    width: 75%;
                }
                .willowimpressionplan--push-price--item--cmjn & {
                    margin-right: auto;
                    width: 75%;
                }
            }
        }
        &--title {
            @include montserrat(600);
            @include font-size-lh(24,31);
            color: $blue-grey;
            @include mq(768px,null){
                @include font-size-lh(30,31);
            }
        }
        &--text {
            @include montserrat();
            @include font-size-lh(13,18);
            color: $blue-grey;
            @include mq(768px,null){
                @include font-size-lh(18,18);
            }
        }
        &--subtext {
            margin-top:20px;
            @include montserrat();
            @include font-size-lh(12,18);
            color: $blue-grey;
            @include mq(768px,null){
                @include font-size-lh(14,18);
            }
        }
        &--hover {
            display: none;
            background-color: $grey;
            @include transition(opacity 0.5s);
            &.active {
                display: block;
            }
            @include mq(1024px,null){
                display: block;
                pointer-events: none;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                opacity: 0;
            }
            &--inner {
                display: flex;
                justify-content: space-between;
                padding: 15px;
                flex-direction: column;
                @include mq(1024px,null){
                    flex-wrap:wrap;
                    flex-direction: row;
                    padding: 40px 30px;
                }
            }
            &--title-txt {
                @include montserrat(600);
                color: $blue-grey;
                text-align: center;
                display: none;
                @include font-size-lh(20,25);
                @include mq(1024px,null){
                    @include font-size-lh(25,30);
                }
                @include mq(1023px,null){
                    display: block;
                    padding-right: 30px;
                    flex-basis:30%;
                    .willowimpressionplan--push-price--item--cmjn & {
                        padding-left: 30px;
                        padding-right: 0;
                    }
                }
            }
            &--title {
                border-bottom: 2px solid $blue-grey;
                margin-bottom: 12px;
                padding-bottom: 8px;
                //@include font-size-lh(25,30);
                //@include mq(1024px,null){
                //    @include font-size-lh(25,30);
                //}
            }
            &--text {
                //@include font-size-lh(20,25);
                //@include mq(1024px,null){
                //    @include font-size-lh(25,30);
                //}
            }
            &--subtext {
                margin-top:20px;
                @include montserrat();
                @include font-size-lh(12,18);
                color: $blue-grey;
                @include mq(768px,null){
                    @include font-size-lh(14,18);
                }
            }
            &--prices {
                @include mq(1024px,null){
                    width: 300px;
                    flex-basis:70%;
                    padding: 0 15px;
                }
                &--row {
                    @include montserrat();
                    @include font-size-lh(16, 16);
                    color: $blue-grey;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    padding: 10px;
                    &:not(:first-child) {
                        &:before {
                            content: '';
                            background-image: url('/themes/impression-plan/assets/dest/img/border-push-prices.svg');
                            background-repeat: repeat-x;
                            height: 2px;
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                        }
                    }
                }
                &--price {
                    @include montserrat(700);
                    text-align: right;
                    white-space: nowrap;
                }
                &--title {
                    white-space: nowrap;
                }
            }
        }
    }
    &--form-upload {
        width: $widthCart;
        margin: 40px auto;
        display: flex;
        position: relative;
        transition:.25s opacity;
        max-width: 100%;
        @include mq(null,767px) {
            position: sticky;
            z-index: 20;
            top: 0;
            bottom: 60px;
        }
        @include mq(768px, null) {
            min-height: 180px;
        }
        &--area {
            flex:1;
            display: flex;
            position: relative;
            overflow: hidden;
            @include mq(null,767px) {
                padding:10px 15px;
                margin: 0 -15px;
            }
            @include mq(768px, null) {
                padding:20px;
                border: 20px solid rgba(255, 255, 255, 0);
            }

            &:before {
                content:"";
                background:url(/themes/impression-plan/assets/dest/img/bg-willowimpressionplan-form-upload-motif.png);
                opacity: 1;
                position: absolute;
                z-index: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-15deg);
                width: 150%;
                height: 300%;
            }
            &--content {
                position: relative;
                flex:1;
                display: flex;
                flex-direction:column;
                align-items: center;
                justify-content: center;
                border-image-repeat: round;
                //border-image-slice: 5;
                //border-image-source: url('/themes/impression-plan/assets/dest/img/border-form-upload.svg');
                //border-image-width: 5px;

                @include mq(null,767px) {
                    padding:10px;
                    border: 2px dashed rgba($pink, 0.8);
                }
                @include mq(768px, null) {
                    padding:20px;
                    border: 5px dashed rgba($pink, 0.8);
                }
            }
            &.active {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 100;
                .willowimpressionplan--form-upload--area--content {
                    pointer-events: none;
                }
                &:before {
                    pointer-events: none;
                    opacity:.75;
                }
            }
        }
        &--cta {
            color: $white;
            background-color: $pink;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;
            @include transition(all 0.3s);
            @include mq(null,767px){
                @include montserrat(500);
                @include font-size-lh(16,20);
                padding:10px;
            }
            @include mq(768px,null){
                @include montserrat(600);
                @include font-size-lh(23,25);
                padding: 10px 15px;
            }
            &.small {
                @include montserrat(400);
                @include font-size-lh(14,15);
                padding: 5px 10px;
                display: inline-flex;
                &:before {
                    display: none;
                }
            }
            &:before {
                content:"";
                background:url(/themes/impression-plan/assets/dest/img/picto-download.svg) no-repeat;
                background-size: contain;
                position: absolute;
                z-index: 1;
                top: 50%;
                right: 100%;
                width: 80px;
                height: 107px;
                opacity: 0.5;
                transform: translate(-50%, -50%) rotate(-15deg);
            }
            &:hover {
                background-color: $blue-grey;
            }
            > span {
                @include mq(null, 767px) {
                    width: calc(100% - 30px);
                }
                @include mq(768px, null) {
                    width: calc(100% - 45px);
                }
            }
            > svg {
                margin-left: 10px;
                @include mq(null, 767px) {
                    width: 20px;
                }
                @include mq(768px, null) {
                    width: 35px;
                }
            }
        }
        &--status {
            &:empty {
                margin:0;
            }
            margin-top:10px;
            @include montserrat(400);
            @include font-size-lh(16,22);
            position: relative;
            z-index: 10;
            display: block;
            width: 100%;
            list-style-position: inside;
            span {
                display: list-item;
            }
            .txt-error {
                color: $error;
            }
            &.small {
                @include font-size-lh(13, 16);
                span {
                    display: inline-block;
                }
            }
        }
        //&--drop-zone {
        //    pointer-events: none;
        //    position: fixed;
        //    top: 20px;
        //    bottom: 20px;
        //    left: 20px;
        //    right: 20px;
        //    z-index: 100;
        //    padding: 20px;
        //    display: flex;
        //    opacity:0;
        //    transition:.25s opacity;
        //    &:before {
        //        content:"";
        //        background:url(/themes/impression-plan/assets/dest/img/bg-willowimpressionplan-form-upload.jpg);
        //        opacity: .75;
        //        top: 0;
        //        left: 0;
        //        bottom: 0;
        //        right: 0;
        //        position: absolute;
        //        z-index: -1;
        //    }
        //    &.active {
        //        opacity: 1;
        //        + .willowimpressionplan--form-upload {
        //            opacity: 0;
        //        }
        //    }
        //    &--content {
        //        border: 5px dashed rgba($pink, 0.8);
        //        flex:1;
        //        display: flex;
        //        align-items: center;
        //        justify-content: center;
        //    }
        //}
        &--file {
            display: none;
        }
    }
    &--holidays {
        &--txt {
            text-align: center;
            @include font-size-lh(14,14);
            font-weight:bold;
            color: $pink;
        }
    }
    &--shipping {
        &--txt {
            text-align: center;
            @include font-size-lh(16,22);
            font-weight:bold;
            color: $pink;
        }
    }
    &--steps {
        display: flex;
        width: $widthCart;
        margin: 20px 0 20px -15px;
        position: relative;
        max-width: calc(100% + 30px);
        @include mq(1024px,null){
            max-width: calc(100% - 50px);
            margin: auto;
            &:before {
                content: '';
                position: absolute;
                top: 34px;
                left: 0;
                right: 0;
                z-index: 5;
                height: 3px;
                //background-image: url('/themes/impression-plan/assets/dest/img/border-steps.svg');
                //background-position: 8% 0;
                //background-repeat: repeat-x;
                border-bottom: 3px dotted $pink;
            }
        }
        @include mq(null,1023px){
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
    }
    &--step {
        background-color: $blue-grey;
        padding: 15px 0 10px;
        margin-left: 10px;
        flex: 1 0 0;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 100%;
            width: 30px;
            top: 0;
            @include mq(null, 767px) {
                background-size: contain;
            }
        }
        &:before {
            background-image: url('/themes/impression-plan/assets/dest/img/step-arrow.svg');
            right: -30px;
            z-index: 3;
        }
        &:after {
            background-image: url('/themes/impression-plan/assets/dest/img/step-arrow-white.svg');
            right: -40px;
            z-index: 2;
        }
        &--01 {
            margin: 0;
        }
        &--inner {
            padding-left: 40px;
            @include mq(null,1023px){
                min-width: 260px;
            }
            .willowimpressionplan--step--01 & {
                padding-left: 20px;
                padding-right:  20px;
            }
        }
        &--num {
            position: relative;
            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 15;
            }
            &:before {
                top: -9px;
                left: 8px;
                width: 4px;
                height: 9px;
                background-color: $pink;
            }
            &:after {
                top: 0;
                left: -1px;
                width: 9px;
                height: 4px;
                background-color: $white;
            }
            span {
                @include montserrat(700);
                @include font-size-lh(38, 38);
                color: $pink;
                padding: 0 10px;
                background-color: $blue-grey;
                display: inline-block;
                position: relative;
                z-index: 10;
            }
        }
        &--title-txt {
            margin-top: 10px;
            padding: 0 10px;
            @include mq(null,1279px){
                br {
                    display: none;
                }
            }
        }
        &--title {
            @include montserrat(600);
            @include font-size-lh(18, 20);
            color: $white;
        }
        &--txt {
            @include montserrat(600);
            @include font-size-lh(12, 15);
            color: $white;
            margin-top: 2px;
        }
    }
    &--cart-comp {
        margin: auto;
        margin-top: 30px;
        display: flex;
        width: 100%;

        &--content {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
            width:100%;
        }
        &--body {
            width: 100%;
        }

        .product-line-grid {
            width: $widthCart;
            max-width: 100%;
        }
        .product-line-info {
            text-align:left;
            margin-left: .75em;
        }
        .product-price {
            color: $blue-grey;
            @include montserrat(400);
            @include font-size-lh(12,15);
            display: flex;
            flex-direction: column;
        }
        .product-discount {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .regular-price {
                text-align: center;
                margin: 0;
            }
        }
        .has-discount .discount {
            margin: 5px auto;
            font-size: .8rem;
            padding: .2125rem .425rem;
        }
        .product-image img {
            width:50px;
        }

        .qty {
            input {
                @include montserrat();
                @include font-size-lh(13);
                color: $white;
                text-align: right;
                width: 40px;
                height:auto;
                padding: 5px;
                background-color: $blue-grey;
                border: none;
                &[disabled] {
                    opacity: .7;
                }
            }
        }

        &--head {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 3px solid $pink;
            background-color: $blue-grey;

            &--col {
                @include montserrat(700);
                @include font-size-lh(15);
                color: $white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &--body {
            &--col {
                @include montserrat();
                @include font-size-lh(13);
                color: $blue-grey;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mq(null, 767px) {
                    justify-content: flex-start;
                    margin: 5px 0;
                }
                > * {
                    align-items: center;
                }
            }
        }

        &--rows {
            position: relative;
            &.wait:before {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: block;
                background: rgba(255,255,255,0.6);
            }
        }
        &--row {
            text-align: center;
            display: flex;
            justify-content: space-between;
            padding: 15px;
            position: relative;
            @include mq(null,767px){
                flex-direction: column;
                > * {
                    width: 100% !important;
                }
            }
            @include mq(null,767px){
                border-bottom: 2px solid $white;
            }
            @include mq(768px,null){
                &:after {
                    content: '';
                    //background-image: url('/themes/impression-plan/assets/dest/img/border-steps.svg');
                    //background-repeat: repeat-x;
                    height: 3px;
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;
                    border-bottom: 3px dotted $pink;
                }
            }
            &:nth-child(2n) {
                background-color: rgba($pink, 0.4);
            }
            &:nth-child(2n+1) {
                background-color: rgba($pink, 0.2);
            }
        }


        &--name {
            width: 50%;
            text-align: left;
            justify-content: flex-start;
        }

        &--unit-price,
        &--quantity,
        &--total-price {
            width: 16%;
            text-align: center;
        }

        &--remove {
            width: 3%;
            .willowimpressionplan--cart-comp--body & {
                cursor: pointer;
            }
        }
        a {
            color:$blue-grey;
        }
    }
    &--cart {
        margin: auto;
        margin-top: 40px;
        display: flex;
        width: $widthCart;
        max-width: 100%;
        &--none {
            display: none;
        }
        &--content {
            display: flex;
            flex-direction: column;
            flex: 1 0 0;
            width:100%;
        }
        &--head {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 3px solid $pink;
            background-color: $blue-grey;
            &--col {
                @include montserrat(700);
                @include font-size-lh(15);
                color: $white;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &--rows {
            position: relative;
            &.wait:before {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: block;
                background: rgba(255,255,255,0.6);
            }
        }
        &--body {
            &--col {
                @include montserrat();
                @include font-size-lh(13);
                color: $blue-grey;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mq(null,767px){
                    justify-content: flex-start;
                    margin: 5px 0;
                }
                p {
                    @include montserrat();
                    @include font-size-lh(13);
                    color: $blue-grey;
                    margin: 0;
                }
                input,
                select {
                    @include montserrat();
                    @include font-size-lh(13);
                    color: $white;
                    text-align: right;
                    width: 40px;
                    padding: 5px;
                    background-color: $blue-grey;
                    border: none;
                }
                select {
                    width: 70px;
                    appearance: none;
                    padding-right: 25px;
                }
                label {
                    display:flex;
                    flex-wrap:nowrap;
                    align-items:center;
                    //white-space: nowrap;
                    font-size:1em;
                    margin: 0;
                }
                input[type="checkbox"]{
                    width:auto;
                    margin-left:1em;
                }
            }
        }
        &--row {
            text-align: center;
            display: flex;
            justify-content: space-between;
            padding: 15px;
            position: relative;
            @include mq(null,767px){
                flex-direction: column;
                > * {
                    width: 100% !important;
                }
            }
            &--matrix {
                display: none !important;
            }
            @include mq(null,767px){
                border-bottom: 2px solid $white;
            }
            @include mq(768px,null){
                &:after {
                    content: '';
                    //background-image: url('/themes/impression-plan/assets/dest/img/border-steps.svg');
                    //background-repeat: repeat-x;
                    height: 3px;
                    position: absolute;
                    right: 0;
                    bottom: -1px;
                    left: 0;
                    border-bottom: 3px dotted $pink;
                }
            }
            &:nth-child(2n) {
                background-color: rgba($pink, 0.4);
            }
            &:nth-child(2n+1) {
                background-color: rgba($pink, 0.2);
            }
            &--progress {
                .new-file & {
                    opacity: 1;
                    pointer-events: initial;
                }
                pointer-events: none;
                opacity:0;
                transition:.25s all;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                .bg {
                    position: absolute;
                    top: 0;
                    left: 20%;
                    height: calc(100% - 3px);
                    width: 80%;
                    z-index: 1;
                    @include linear-gradient(90deg, rgba(255,255,255,0) 0%,rgba(255,255,255,.7) 15%);
                    //backdrop-filter: blur(3px);
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    padding-left: 5%;
                }
                .line {
                    height: 10px;
                    width: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    //opacity: .75;
                    transition: .5s all;
                    //animation: move 2s linear infinite;
                    background-size: 50px 50px;
                    background:green;
                    //background-image: linear-gradient(
                    //                -45deg,
                    //                $blue-grey 25%,
                    //                transparent 25%,
                    //                transparent 50%,
                    //                $blue-grey 50%,
                    //                $blue-grey 75%,
                    //                transparent 75%,
                    //                transparent
                    //);
                    //@keyframes move {
                    //    0% {
                    //        background-position: 0 0;
                    //    }
                    //    100% {
                    //        background-position: 50px 50px;
                    //    }
                    //}
                }
            }
        }

        &--name {
            .willowimpressionplan--cart--file-name {
                word-break: break-word;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        @include mq(null,767px){
            &--name {
                flex-wrap: wrap;
                //.willowimpressionplan--cart--file-name {
                //    word-break: break-word;
                //    width: 100%;
                //    overflow: hidden;
                //    text-overflow: ellipsis;
                //}
            }
            &--print-dimensions {
                flex-wrap: wrap;
                align-items: flex-start;
                &--content {
                    flex: 1;
                    white-space: nowrap;
                    text-align:right;
                }
                .willowimpressionplan--cart--enlargement {
                    width:100%;
                }
            }
            &--print-dimensions,
            &--color,
            &--calculation-price,
            &--quantity,
            &--price-wt,
            &--area,
            &--doublesided,
            &--booklet,
            &--options,
            &--remove {
                .willowimpressionplan--cart--head & {
                    display: none !important;
                }
                &:before {
                    content: attr(data-th);
                    @include montserrat(600);
                    @include font-size-lh(12,18);
                    color: $blue-dark;
                    margin-right: auto;
                }
                margin-left: auto;
            }
            &--price-wt {
                @include montserrat(600);
                @include font-size-lh(12,15);
            }
        }


        &--name {
            align-items: flex-start;
            width: 20%;
            text-align: left;
        }
        &--name,
        &--print-dimensions {
            @include mq(768px,null){
                flex-direction: column;
            }
        }
        &--color,
        &--options {
            width: 16%;
            label {
                white-space: normal;
            }
        }
        &--print-dimensions {
            width: 10%;
            @include mq(768px, null) {
                &--content {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
        &--folding,
        &--quantity {
            width: 7%;
        }
        &--area,
        &--unit-price,
        &--price-wt {
            width: 9%;
        }
        &--calculation-price {
            width: 17%;
        }

        &--color,
        &--folding,
        &--quantity {
            span {
                color: $white;
                padding: 5px;
                margin-left: -18px;
                pointer-events: none;
                background-color: $blue-grey-lighten;
            }
        }
        &--options {
            display:flex;
            flex-direction: column;

            > div {
                + div {
                    margin-top: 10px;
                }
            }
        }
        &--enlargement {
            position: relative;
            flex-wrap: wrap;
            @include mq(null,767px){
                justify-content: flex-end;
            }
            @include mq(768px,null){
                margin-top: 1em;
                width: 100%;
            }
            span {
                color: $white;
                background-color: $blue-grey-lighten;
                padding: 5px;
            }
            input[type="checkbox"]:checked ~ &--content {
                display: flex;
            }
            &--content {
                width:100%;
                display: none;
                justify-content: center;
                margin-top:.5em;
                flex-wrap: nowrap;
                @include mq(null,767px){
                    justify-content: flex-end;
                }
            }
        }
        &--color {
            flex-wrap: wrap;
            width: 100%;
        }
        &--radio {
            display: flex;
            margin: 0 5px;
            input {
                width: auto;
            }
        }
        &--remove {
            width: 3%;
            .willowimpressionplan--cart--body & {
                cursor: pointer;
            }
        }
        &--footer {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            @include mq(null,767px) {
                flex-direction: column;
            }
        }
        &--client-reference {
            @include mq(768px,null){
                width: 50%;
            }
            @include mq(1024px,null){
                width: 33%;
            }
            label {
                @include montserrat(700);
                @include font-size-lh(15);
                color: $white;
                display: flex;
                padding: 15px;
                border-bottom: 3px solid $pink;
                background-color: $blue-grey;
                margin: 0;
            }
            textarea {
                @include montserrat();
                @include font-size-lh(13);
                color: $blue-grey;
                background-color: rgba($grey, 0.6);
                border: none;
                resize: none;
                &:focus {
                    color: $blue-grey;
                    background-color: rgba($grey, 0.4);
                    outline: none;
                }
            }
        }
        &--detailed-totals {
            @include mq(768px,null){
                width: 50%;
            }
            @include mq(1024px,null){
                width: 33%;
            }

            &.wait &--row:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: block;
                background: rgba(255,255,255,0.6);
            }


            &--row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                @include mq(null,767px){
                    border-bottom: 2px solid $white;
                }
                @include mq(768px,null){
                    &:not(:first-child) {
                        &:after {
                            content: '';
                            //background-image: url('/themes/impression-plan/assets/dest/img/border-steps.svg');
                            //background-repeat: repeat-x;
                            height: 3px;
                            position: absolute;
                            right: 0;
                            bottom: -1px;
                            left: 0;
                            border-bottom: 3px dotted $pink;
                        }
                    }
                }
                &:nth-child(2n) {
                    background-color: rgba($pink, 0.4);
                }
                &:nth-child(2n+1) {
                    background-color: rgba($pink, 0.2);
                }
            }
            &--title,
            &--price {
                @include montserrat(600);
                @include font-size-lh(15, 18);
                color: $blue-grey;
                text-align: right;
                padding: 10px 15px;
                width: 50%;
            }
            &--price {
                &:not(.willowimpressionplan--cart--detailed-totals--total) {
                    @include montserrat();
                }
            }
            &--cta {
                margin-top: 20px;
                position: relative;
                &.wait:before {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    display: block;
                    background: rgba(255,255,255,0.6);
                }

                &--link {
                    display: flex;
                    justify-content: flex-end;
                    text-decoration: none;
                    outline: none;
                    position: relative;
                }
                &--title {
                    @include montserrat(600);
                    @include font-size-lh(23, 25);
                    color: $white;
                    padding: 10px 15px;
                    background-color: $pink;
                    text-align: right;
                    @include transition(background 0.3s);
                    @include mq(null,767px){
                        width: 100%;
                        text-align: center;
                    }
                    &:hover {
                        background-color: $blue-grey;
                    }
                    svg {
                        margin-left: 10px;
                    }

                    small {
                        display: block;
                        margin-top: 5px;
                        @include montserrat(300);
                        @include font-size-lh(12,14);
                    }
                }
            }
        }
        &--order {
            color: black;
            display: inline-block;
            padding: 10px 20px;
            margin-top: 20px;
            border: 1px solid black;
            background: rgba(0, 0, 0, 0.2);
        }
    }

    .products {
        @include mq(768px, null) {
            display: flex;
            gap: 25px;
        }
    }

    &--push-products {
        background: rgba(204,37,120,.2);
        margin-top: 30px;
        padding: 20px 0;
        h2 {
            padding: 0 20px;
        }
    }
}

.hidden {
    display:none !important;
}