.header {
    &--holidays {
        background-color: $pink;
        padding: 10px;
        &--txt {
            @include font-size-lh(20, 27.5);
            font-weight: 700;
            text-align: center;
            color: $white;
            margin: 0;
        }
    }
    &--banner {
        padding: 20px;
        color: white;
        background: $pink;
        text-align: center;
        @include montserrat(400);
        @include font-size-lh(16, 20);

        &--text {
            animation: blink-animation 1.5s steps(10, start) infinite;
        }
        @keyframes blink-animation { to { visibility: hidden; }}
    }
    &--row {
        position: relative;
        width: 100%;
        min-height: 154px;
        background-color: $blue-grey;
        background-image: url('/themes/impression-plan/assets/dest/img/bg-header.jpg');
        background-position: 50% 100%;
        background-repeat: no-repeat;
        &--home {
            background-image: url('/themes/impression-plan/assets/dest/img/bg-header-home.jpg');
            //background-image: url('/themes/impression-plan/assets/dest/img/bg-header-home-vacances.jpg');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            //@include mq(768px,null){
            //    height: 470px;
            //}
        }
        &--inner {
            display: flex;
            .header--row--home & {
                align-items: center;
                flex-direction: column;
                padding: 60px 0 0 0;
                @include mq(768px,null){
                    padding: 60px 0;
                }
            }
        }
    }
    &--logo {
        > a {
            display: block;
        }
        img {
            @include mq(null,767px){
                width: 38vw;
            }
        }
        &--page {
            position: relative;
            width: 118px;
            height: 128px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding-top: 12px;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 128px;
                border-bottom: 118px solid $white;
                border-left: 0px solid transparent;
                border-right: 9px solid transparent;
                transform: translateX(-4.5px) rotate(90deg);
            }
            img {
                position: relative;
            }
            a {
                padding: 5px;
            }
        }
        .logo {
            width: 94px;
            height: 83px;
            position: relative;
            z-index: 5;
            .header--row--home & {
                width: 224px;
                height: 197px;
            }
            .cls-5 {
                fill: $pink;
            }
        }
        &--navbar {
            //@include mq(null,359px){
            //    display: none;
            //}
            @include mq(1024px,null){
                display: none;
            }
            &--link {
                display: flex;
                padding: 5px;
                background-color: white;
                justify-content: center;
                align-items: center;
                height: 100%;
                img {
                    width: 60px !important;
                }
            }
        }
    }
    &--shipping {
        width: calc(100% - 236px);
        height: 77px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 118px;
        &--txt {
            color: $white;
            margin-bottom: 0;
        }
    }
    &--text {
        @include montserrat(900);
        @include font-size-lh(25,28);
        color: $blue-grey;
        text-align: center;
        text-transform: uppercase;
        margin-top: 20px;
        @include mq(1024px,null){
            @include font-size-lh(39,39);
        }
    }
    &--cta {
        margin-top: 15px;
        cursor: pointer;
        width: 100%;
        padding-bottom: 40px;
        &--title {
            @include montserrat(600);
            @include font-size-lh(20, 20);
            color: $white;
            text-transform: uppercase;
            width: 420px;
            max-width: 100%;
            height: 40px;
            background-color: $pink;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }
    }
    &--help-faq-account-contact {
        @include mq(null,1023px){
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            max-width: 100vw;
            z-index: 100;
            background-color: $white;
            display: flex;
            > * {
                flex: 1 1 0;
            }
        }
        @include mq(1024px,null){
            position: absolute;
            top: 20px;
            right: 15px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }
    &--help,
    &--faq,
    &--user,
    &--logout,
    &--contact {
        a {
            display: flex;
            align-items: center;
            padding: 3px;
            outline: none;
            text-decoration: none;
            @include mq(null,1023px){
                flex-direction: column;
                justify-content: center;
                padding: 8px 5px;
                height: 100%;
                color: $blue-dark;
                background-color: $blue-dark;
                &:hover {
                    color: $blue-dark;
                }
            }
            @include mq(1024px,null){
                @include transition(all 0.3s);
                background-color: $white;
                border-radius: 16px;
            }
        }
        &.active,
        &:hover {
            a {
                background-color: $pink;
            }
            .header--icon {
                background:$white;
                i {
                    color: $pink;
                }
            }
            .header--title {
                color: $white;
            }
        }
    }

    @include mq(null,1023px) {
        &--faq {
            order:1;
        }
        &--contact {
            order:2;
        }
        &--logo--navbar {
            order:3;
        }
        &--user {
            order:4;
        }
        &--logout {
            order:5;
        }
    }

    &--logout  {

        $logoutGrey: #999;
        a {
            @include mq(null, 1023px) {
                color: white !important;
                background-color: $logoutGrey !important;
                &:hover {
                    color: white !important;
                }
            }
            @include mq(1024px, null) {
                background-color: $logoutGrey !important;
            }

            .header--icon,
            .header--icon i,
            .header--title {
                background-color: $logoutGrey !important;
                color: white !important;
            }

        }
    }

    &--title {
        text-transform: uppercase;
        margin: 0 5px;
        @include mq(null,1023px){
            @include montserrat(400);
            @include font-size-lh(10,20);
            color: $white;
        }
        @include mq(1024px,null){
            @include transition(all 0.3s);
            @include montserrat(700);
            @include font-size-lh(11, 14);
            color: $blue-grey;
        }

    }
    &--faq,
    &--user,
    &--logout,
    &--contact {
        @include mq(1024px,null){
            margin-left: 10px;
        }
    }
    &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        @include mq(null, 1023px){
            background-color: $white;
        }
        @include mq(1024px,null){
            @include transition(all 0.3s);
            background-color: $blue-grey;
        }
        i {
            @include font-size-lh(14, 14);
            @include mq(null,1023px){
                color: $blue-dark;
            }

            @include mq(1024px,null){
                color: $white;
                @include transition(all 0.3s);
            }
        }
    }
    @include mq(null,1023px){
        &--help > a:active .header--icon {
            background-color: $pink;
        }
        &--help,
        &--faq,
        &--user {
            > a {
                &:active {
                    background-color: $white;
                    color: $pink;
                    .header {
                        &--icon,
                        &--icon i,
                        &--title {
                            color: $pink;
                        }
                        &--icon span {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}
.page--header {
    display: flex;
    justify-content: center;
    margin: 25px 0;
    @include mq(768px,null){
        margin-top: 83px;
        margin-bottom: 57px;
    }
    &--title {
        @include montserrat(900);
        @include font-size-lh(32,40);
        color: $blue-grey;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 7px;
        text-align: center;
        @include mq(768px,null){
            @include font-size-lh(52,63);
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 400px;
            max-width: 100%;
            height: 3px;
            background-color: $pink;
        }
        i {
            height: 1.1em;
            font-size: 62px;
        }
    }
}