.search {
    &--widget {
        width: 100%;
    }
    &--form {
        position: relative;
        background-color: $grey;
        width: 100%;
        @include mq(768px,null){
            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 15;
            }
            &:before {
                top: -19px;
                left: -71px;
                width: 52px;
                height: 19px;
                background-color: $blue-grey;
            }
            &:after {
                top: -71px;
                left: -19px;
                width: 19px;
                height: 52px;
                background-color: $pink;
            }
        }
        &--inner {
            position: relative;
            padding: 15px;
            @include mq(768px,null){
                padding: 30px 40px;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 15;
                }
                &:before {
                    bottom: -19px;
                    right: -71px;
                    width: 52px;
                    height: 19px;
                    background-color: $pink;
                }
                &:after {
                    bottom: -71px;
                    right: -19px;
                    width: 19px;
                    height: 52px;
                    background-color: $blue-grey;
                }
            }
        }
    }
}