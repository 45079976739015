.push--ask-question {
    &--inner {
        background-position: 75% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        padding: 20px 0 0 0;
        @include mq(768px,null){
            height: 548px;
            padding: 0;
        }
    }

    &--content {
        width: 445px;
        max-width: 100%;
    }

    &--text {
        @include montserrat(500);
        @include font-size-lh(24,34);
        color: $white;
        text-align: center;
        background-color: $blue-grey;
        padding: 20px 20px 50px 20px;
        @include mq(null,767px){
            br {
                display: none;
            }
        }
        @include mq(768px,null){
            @include font-size-lh(26,32);
        }
        @include mq(1024px,null){
            padding: 75px 95px;
        }
    }

    &--cta {
        display: flex;
        justify-content: center;

        &--title {
            @include montserrat(700);
            @include font-size-lh(24, 24);
            color: $white;
            text-decoration: none;
            outline: none;
            display: inline-block;
            padding: 20px 30px;
            background-color: $pink;
            border-radius: 41px;
            text-align: center;
            @include translate(0, -50%);
            @include transition(all 0.3s);

            &:hover {
                color: $pink;
                background-color: $white;
            }
        }

    }
}