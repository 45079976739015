.breadcrumb {
    margin: 20px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include font-size-lh(10,20);
    @include mq(768px,null){
        @include font-size-lh(12,20);
    }
    &--ol {
        display: flex;
        align-items: center;
    }
    &--li {
        display: flex;
        &:last-child {
            .breadcrumb--title {
                @include montserrat(500);
                color: $pink;
            }
        }
        &:after {
            @include montserrat(600);
            color: $blue-grey;
        }
    }
    &--title {
        @include montserrat(600);
        color: $blue-grey;
    }
}