.form {
    &--field {
        $widthBtnSearch: 62;
        $marginBtnSearch: 7;
        &--inner {
            display: flex;
            align-items: center;
        }
        &--wrap {
            &--search {
            }
        }
        &--input {
            @include montserrat(700);
            @include font-size-lh(16, 19);
            color: $placeholder;
            width: 100%;
            height: 70px;
            padding: 0 20px;
            border: none;
            border-radius: 79px;
            transition: all 0.3s;
            @include mq(768px,null){
                padding: 0 40px;
            }
            .form--field--wrap--search & {
                width: calc(100% - 62px);
                margin-right: 10px;
                @include mq(768px,null){
                    width: calc(100% - #{$widthBtnSearch}px - #{$marginBtnSearch}px);
                }
            }
        }
        &--button {
            .form--field--wrap--search & {
                background-color: transparent;
                border: none;
                padding: 0;
                width: 62px;
                .picto-search {
                    width: 100%;
                }
                @include mq(768px,null){
                    flex: 1 0 #{$widthBtnSearch}px;
                    height: #{$widthBtnSearch}px;
                    margin-left: 44px;
                    margin-right: 13px;
                    .picto-search {
                        width: 62px;
                        height: 62px;
                    }
                }
            }
        }
    }
}